import React from 'react';
import './App.css';
import Paper from './Paper';

function App() {
  return (
    <div className="App">
      <Paper />
    </div>
  );
}

export default App;